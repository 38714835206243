import React, { Component } from "react";

import Header from "./Header";
import Menu from "./Menu";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import RightPanel from "./RightPanel";

export default class Main extends Component {
  state = {
    guests: [],
    guest: [],
    attendances: [],
    activities: [],
    attn: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      guests: [],
      guest: [],
      attendances: [],
      activities: [],
      attn: [],
      attendanceHeight: "100%",
      dailyActivityHeight: "100%",
    };
  }

  fetch() {
    var context = this;

    $.ajax({
      url: "https://yolo-student-api.jagathsoft.com/api/guest_dashboard/1",
      method: "GET",
      success: function (response) {
        context.setState({
          guests: response.recordset,
          guest: response.recordset[0],
          attendances: response.recordsets[1],
          activities: response.recordsets[2],
          attn: response.recordsets[3],
        });
      },
      error: function (error) {
        console.log("error: " + error);
      },
    });
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    return (
      <div className="app-page">
        <div class="wrapper">
          {/* <Header/> */}
          <Menu />
          <Dashboard
            guest={this.state.guest}
            attendances={this.state.attendances}
            activities={this.state.activities}
          />
          <RightPanel guest={this.state.guest} />
          {/* <Footer/> */}
        </div>
      </div>
    );
  }
}
