import React, { Component } from 'react'
import GaugeChart from 'react-gauge-chart'

export default class RightPanel extends Component {

    constructor(props) {
        super(props);
    }

    render() {
      const { guest } = this.props;
      console.log(guest);

        return (
        <div class="r-sidebar d-none d-md-block">
            <h2 class="secondary-title mb-5">My Profile</h2>
            <div class="r-sidebar-content">
                <div class="text-center">
                    <img class="user-img" src="dist/img/user.png" alt=""/>
                    <h3 class="user-name">{guest.first_name}</h3>
                    <div class="user-email">anthony@gmail.com</div>
                </div>
                <div class="profile-progress">
                    <h4 class="profile-progress-title">Profile Completeion</h4>
                    <GaugeChart
                     className='img-fluid'
                     id="gauge-chart1"
                     nrOfLevels={10} 
                     arcPadding={0.1} 
                     percent={guest.profile_score / 100}
                     colors={["#0000FF", "#00FFFF"]} 
                     textColor="#000000"
                     needleBaseColor="#000000"
                     animate={false}
                    //  arcsLength={[0.3, 0.5, 0.2]}
                    />
                </div>
            </div>
        </div>

        )
    }
}