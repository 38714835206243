import React, { Component } from "react";
import { Link } from "react-router-dom";
let installPrompt;
export default class Menu extends Component {
  //isHideMenu
  constructor(props) {
		super(props);
		this.state = {
			isHideMenu: false,
      isAppInstalled: true,
      hideInstallPrompt: "hide-prompt",
		};
	}
  sideBarClosed=(e)=>{
    document.querySelector("body").classList.remove("sidebar-open");
    $(".collapse").removeClass("show");
  }
  installApp() {
    // Prompt users to download pwa
    window.addEventListener("beforeinstallprompt", (e) => {
      console.log('beforeinstallprompt fired Menu.js');
      e.preventDefault();
      installPrompt = e;
      this.setState({ showDownloadMenu: true, isAppInstalled: false },()=>{
        this.toggleInstallPrompt();
    installPrompt.prompt();
    installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        this.setState({ isAppInstalled: true });
      } else {
      }
      installPrompt = null;
    });
      });
    });
  }
  toggleInstallPrompt() {
    if (
      this.state.hideInstallPrompt === "hide-prompt" &&
      this.state.isAppInstalled === false &&
      installPrompt &&
      window.screen.width < 768
    ) {
      this.setState({ hideInstallPrompt: "show-prompt" });
    } else if (this.state.hideInstallPrompt === "show-prompt") {
      this.setState({ hideInstallPrompt: "hide-prompt" });
    }
  }
  downloadApp(e) {
    if (installPrompt) {
      installPrompt.prompt();
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          this.setState({ isAppInstalled: true });
        } else {
        }
        installPrompt = null;
      });
    }
    e.preventDefault();
  }
  
  render() {
    this.installApp();
    return (
      <>
      <div class="mobile-menu">
            <nav class="navbar navbar-expand-lg">
                <a class="sidebar-logo mb-5 d-none" href="">
                    <img src="images/logo.svg" alt="" />
                </a>
                <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                 <i class="icon-hamburger text-primary menu-icon"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <div>
                        <div class="mobile-menu-header">
                            <button type="button" class="close-btn" id="c_lose">
                                <i class="icon-l-arrow"></i>
                            </button>
                        </div>
                    </div>
                    <ul class="navbar-nav">
                    <li className="nav-item">
                    {this.props.active == "Dashboard" ? (
                      <Link to="/dashboard" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-dashboard" />
                          Dashboard
                      </Link>
                    ) : (
                      <Link to="/dashboard" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-dashboard" />
                          Dashboard
                      </Link>
                    )}
                  </li>
                  <li className="nav-item">
                    {this.props.active == "Profile" ? (
                      <Link to="/profile" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-profile" />
                          My Profile
                      </Link>
                    ) : (
                      <Link to="/profile" className="nav-link "  onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-profile" />
                          My Profile
                      </Link>
                    )}
                  </li>

                  <li className="nav-item">
                    {this.props.active == "Parents"  ? (
                      <Link to="/parents" className="nav-link active"  onClick={()=>this.sideBarClosed()}>
                        <i className="icon-users" />
                         Parents Details
                      </Link>
                    ) : (
                      <Link to="/parents" className="nav-link "  onClick={()=>this.sideBarClosed()}>
                        <i className="icon-users" />
                          Parents Details
                      </Link>
                    )}
                  </li>

                  <li className="nav-item">
                    {this.props.active == "ChangePassword" ? (
                      <Link to="/changepassword" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="icon-password" />
                          Change Password
                      </Link>
                    ) : (
                      <Link to="/changepassword" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-password" />
                          Change Password
                      </Link>
                    )}
                  </li>

                  <li className="nav-item" hidden>
                    {this.props.active == "Living" ? (
                      <Link to="/living" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-map-marker" />
                        &nbsp;
                        <p>
                          Living Preferences
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    ) : (
                      <Link to="/living" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-map-marker" />
                        &nbsp;
                        <p>
                          Living Preferences
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    )}
                  </li>

                  <li className="nav-item">
                    {this.props.active == "Wallet" ? (
                      <Link to="/wallet" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="icon-bills" />
                          Invoice/Bills
                      </Link>
                    ) : (
                      <Link to="/wallet" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-bills" />
                          Invoice/Bills
                      </Link>
                    )}
                  </li>

                  <li className="nav-item has-treeview" hidden>
                    {this.props.active == "FileUpload" ? (
                      <Link to="/fileupload" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-user" />
                        &nbsp;
                        <p>
                          Notification
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    ) : (
                      <Link to="/fileupload" className="nav-link" onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-user" />
                        &nbsp;
                        <p>
                          Notification
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    )}
                  </li>


                  <li className="nav-item">
                    {this.props.active == " Logout" ? (
                      <Link to="/login" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-logout" />
                          Logout
                      </Link>
                    ) : (
                      <Link to="/login" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-logout" />
                          Logout
                      </Link>
                    )}
                  </li>
                    </ul>
                </div>
            </nav>
            <nav class="navbar ml-auto user-menu">
                <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarNav1" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <img class="user-img" src="images/user.png" alt="" />
                </button>
                <div class="collapse navbar-collapse" id="navbarNav1">
                    <div class="mobile-menu-header">
                        <button type="button" class="close-btn" id="c_lose1">
                                <i class="icon-l-arrow"></i>
                            </button>
                        <h2 class="secondary-title">My Profile</h2>
                    </div>
                    <div class="r-sidebar-content">
                        <div class="text-center">
                            <img class="user-img" src="images/user.png" alt="" />
                            <h3 class="user-name">Anthony</h3>
                            <div class="user-email">anthony@gmail.com</div>
                        </div>
                        <div class="profile-progress text-center">
                            <h4 class="profile-progress-title">Profile Completeion</h4>
                            <img class="img-fluid" src="images/progress.png" alt="" />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
      <div className="side-bar d-none d-md-block" id="menuId">
            <nav className="navbar navbar-expand-lg">
              <a class="sidebar-logo mb-5" href="">
                    <img src="dist/img/logo.svg" alt=""/>
              </a>
              
              <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarNav2" aria-controls="navbarNav2" aria-expanded="false" aria-label="Toggle navigation">
                 <i class="icon-hamburger text-primary menu-icon"></i>
              </button>

              {/* -----------Mobile Button------- */}
              <div class="collapse navbar-collapse" id="navbarNav2">
                
                {/* <div>
                  <div class="mobile-menu-header">
                      <button type="button" class="close-btn" id="c_lose">
                          <i class="icon-l-arrow"></i>
                      </button>
                  </div>
                </div> */}
                <ul
                  className="navbar-nav"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {!this.state.isAppInstalled ? (
                    <li className="nav-item">
                        <a style={{background: "#5bb75b",color:"white",cursor: "pointer"}} className="nav-link" onClick={this.downloadApp}>
                              {" "}
                              {/* <i className="icon-down-arrow" /> */}
                              <img src="dist/img/download.svg" alt="" height={"20px"} width={"20px"} />&nbsp;
                              Download
                            </a>
                        </li>
                  ):null}
                  
                  <li className="nav-item">
                    {this.props.active == "Dashboard" ? (
                      <Link to="/dashboard" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-dashboard" />
                          Dashboard
                      </Link>
                    ) : (
                      <Link to="/dashboard" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-dashboard" />
                          Dashboard
                      </Link>
                    )}
                  </li>

                  <li className="nav-item">
                    {this.props.active == "Profile" ? (
                      <Link to="/profile" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-profile" />
                          My Profile
                      </Link>
                    ) : (
                      <Link to="/profile" className="nav-link "  onClick={()=>this.sideBarClosed()}>
                        {" "}
                        <i className="icon-profile" />
                          My Profile
                      </Link>
                    )}
                  </li>

                  <li className="nav-item">
                    {this.props.active == "Parents"  ? (
                      <Link to="/parents" className="nav-link active"  onClick={()=>this.sideBarClosed()}>
                        <i className="icon-users" />
                         Parents Details
                      </Link>
                    ) : (
                      <Link to="/parents" className="nav-link "  onClick={()=>this.sideBarClosed()}>
                        <i className="icon-users" />
                          Parents Details
                      </Link>
                    )}
                  </li>

                  <li className="nav-item">
                    {this.props.active == "ChangePassword" ? (
                      <Link to="/changepassword" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="icon-password" />
                          Change Password
                      </Link>
                    ) : (
                      <Link to="/changepassword" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-password" />
                          Change Password
                      </Link>
                    )}
                  </li>

                  <li className="nav-item" hidden>
                    {this.props.active == "Living" ? (
                      <Link to="/living" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-map-marker" />
                        &nbsp;
                        <p>
                          Living Preferences
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    ) : (
                      <Link to="/living" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-map-marker" />
                        &nbsp;
                        <p>
                          Living Preferences
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    )}
                  </li>

                  <li className="nav-item">
                    {this.props.active == "Wallet" ? (
                      <Link to="/wallet" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="icon-bills" />
                          Invoice/Bills
                      </Link>
                    ) : (
                      <Link to="/wallet" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-bills" />
                          Invoice/Bills
                      </Link>
                    )}
                  </li>

                  <li className="nav-item has-treeview" hidden>
                    {this.props.active == "FileUpload" ? (
                      <Link to="/fileupload" className="nav-link active" onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-user" />
                        &nbsp;
                        <p>
                          Notification
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    ) : (
                      <Link to="/fileupload" className="nav-link" onClick={()=>this.sideBarClosed()}>
                        <i className="nav-icon fas fa-user" />
                        &nbsp;
                        <p>
                          Notification
                          <i className="fas fa-angle-right right" />
                        </p>
                      </Link>
                    )}
                  </li>


                  <li className="nav-item">
                    {this.props.active == " Logout" ? (
                      <Link to="/login" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-logout" />
                          Logout
                      </Link>
                    ) : (
                      <Link to="/login" className="nav-link " onClick={()=>this.sideBarClosed()}>
                        <i className="icon-logout" />
                          Logout
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </nav>
      </div>
      </>
    );
  }
}
