import React from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import axios from "axios";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import $ from "jquery";
import "./invoice.css";
import logo from "./images/logo.svg";

export const Invoices = ({ invoices }) => {
  const printRef = React.useRef();
  const [invoicedata, setinvoicedata] = useState([]);
  const [guest_invoice_id, setguest_invoice_id] = useState([]);
  console.log(invoicedata);
  const toggle = () => {
    setModal(!modal);
  };
  const getInvoice = async (id) => {
    var dataa = {
      guest_invoice_id: id,
    };

    await axios
      .post(
        `https://yolo-student-api.jagathsoft.com/api/get_invoice_details/1`,
        dataa
      )
      .then((res) => {
        setinvoicedata(res.data.recordset[0]);

        // return res.data;
      });
    toggle();
  };

  const printDocument = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("invoice.pdf");
    setModal(false);
  };
  const [modal, setModal] = useState(false);

  function InvoicePage() {
    return (
      <Modal
        size="lg"
        show={modal}
        style={{
          marginTop: 2,
          hight: "100%",
          width: "100%",
        }}
      >
        <div>
          <Button onClick={printDocument} style={{ width: "100%" }}>
            {" "}
            Download Invoice
          </Button>
        </div>
        <div id="divToPrint" className="print" ref={printRef}>
          <div className="wrapper" id="invoice-container">
            <div className="container-fluid">
              <div className="Header">
                <div className="logo">
                  <img className="logo" src={logo} />
                </div>
                <div className="Address">
                  <p>Original for Customers</p>
                  <h5>
                    <b>TAX INVOICE</b>
                  </h5>
                  <p>Invoice#:{invoicedata.Invoice_Code}</p>
                  <p>
                    Invoice Date:
                    {Moment(invoicedata.invoice_Date).format("MMM-DD-YYYY")}
                  </p>
                </div>
              </div>

              <hr
                style={{
                  background: "l#a4e2ed",
                  color: "l#a4e2ed",
                  borderColor: "#a4e2ed",
                  height: "1px",
                }}
              />

              <div className="Details">
                <div className="">
                  <h6>
                    <b>Details Of Supplier</b>
                  </h6>
                  <h6>YOLO</h6>
                  <h6>3JQM+QFP,6th Cross Rd</h6>
                  <h6>Agrahara Badavance,Bengaluru,Karnataka-560064</h6>
                  <h6>GSTIN:</h6>
                  <h6>Pan No:</h6>
                </div>
                <div className="">
                  <h6>
                    <b>Customer Details:</b>
                  </h6>
                  <p>{invoicedata.first_name + " " + invoicedata.last_name}</p>
                  <p>{invoicedata.email}</p>
                  <p>{invoicedata.phone_number}</p>
                  <p>{invoicedata.Guest_Location}</p>
                </div>
              </div>
              <hr
                style={{
                  background: "l#a4e2ed",
                  color: "l#a4e2ed",
                  borderColor: "#a4e2ed",
                  height: "1px",
                  visibility: "hidden",
                }}
              />
              <div className="table-format">
                <table className="table table-bordered table-cell-padding">
                  <thead>
                    <tr className="table-primary">
                      <th>Description</th>
                      <th>HSN Code</th>
                      <th>Unit</th>
                      <th>Amount Paid</th>
                      <th>Taxable Value</th>
                      <th>CGST (0%)</th>
                      <th>SGST/ UGST (0%)</th>
                      <th>IGST (18%)</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{invoicedata.details} </td>
                      <td></td>
                      <td> </td>
                      <td>{invoicedata.Amount} </td>
                      <td> </td>
                      <td> </td>
                      <td>{invoicedata.Amount * (18 / 100)}</td>
                      <td> </td>
                      <td>
                        {invoicedata.Amount * (18 / 100) + invoicedata.Amount}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total </b>
                      </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td></td>
                      <td>
                        <b>
                          {invoicedata.Amount * (18 / 100) + invoicedata.Amount}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bottom">
                <p>
                  <u>
                    This is a system generated invoice and does not required a
                    digital signature or signature
                  </u>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  function TableButton(paymentStatus) {
    let invoice = paymentStatus;

    if (invoice.paymentStatus === "Paid") {
      var id = invoice.paymentId;

      return (
        <div>
          <InvoicePage style={{ visibility: "hidden" }} />
          <Button
            variant="success"
            id={invoice.invoice_id}
            onClick={() => {
              getInvoice(invoice.invoice_id);
            }}
          >
            Download Invoice
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          style={{ width: "100%" }}
          onClick={() => {
            $("[id='paymentButton']").trigger("click");
            $('input[id="paymentAmount"]').val(invoice.amount);
          }}
        >
          Pay Now
        </Button>
      );
    }
  }
  return invoices.map((invoice) => (
    <>
      <tr key={invoice.guest_invoice_id}>
        <td>
          <b>{invoice.Invoice_Code}</b>
        </td>
        <td>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        <td>
          <b>{Moment(invoice.invoice_Date).format("MMM-DD-YYYY")}</b>
        </td>

        <td>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        <td>
          <b>{invoice.details}</b>
        </td>
        <td>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        <td>
          <b>INR {invoice.amount}</b>
        </td>
        <td>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        <td>
          <b>{invoice.payment_status}</b>
        </td>

        <td>
          <TableButton
            paymentStatus={invoice.Payment_Status}
            paymentId={invoice.Invoice_Code}
            amount={invoice.amount}
            invoice_id={invoice.guest_invoice_id}
          />
        </td>
      </tr>
      <hr style={{ visibility: "hidden" }} />
    </>
  ));
};
