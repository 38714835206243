import React, { Component } from 'react'
import Menu from './Menu.js';
import Dashboard from './Dashboard.js';
import Footer from './Footer.js';
import Header from './Header.js';
import $ from 'jquery';


export default class Changepassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: "password",
    }
  }
  toggleInputType = () => {
		if (this.state.inputType === "password") {
			this.setState({ inputType: "text", passwordIconColor: "#a2a3a5" });
		} else {
			this.setState({ inputType: "password", passwordIconColor: "#232f49" });
		}
	};
  render() {
    return (
        <div className='wrapper'>
            {/* <Header/> */}
            <Menu active="ChangePassword"/>
            <div className="content-wrapper" style={{margin: "0", width: "100%"}}>
              <div className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">

                <div className="col-sm-6">
                  <h1 className="m-0 text-dark" hidden><h4>Change Password</h4></h1>
                </div>

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Change Password</li>
                  </ol>
                </div>
                </div>
              </div>
            <section className="container" style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh',}}>

              <div className="container-fluid" style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10vh',}}>  
                 <div className="row " style={{alignContent: "center", alignItems: "center"}}>
                 <div className="card mb-3" style={{borderRadius: "15px"}}>
                 <section className="col-lg-2 connectedSortable">

                    <div class="login-box" style={{alignContent: "center", alignItems: "center"}}>
                            <div class="card-body login-card-body">
                                <p 
                                // class="login-box-msg"
                                >Set/Reset Password</p>
                                     <form action="login.html" method="post">
                                     <div class="input-group mb-3">
                                    <input type={this.state.inputType} id="pass1" class="form-control" placeholder="Old Password" style={{color:this.state.passwordIconColor}}/>
                                    <div class="input-group-append" onClick={this.toggleInputType}>
                                        <div class="input-group-text">
                                        <span class="fas fa-eye" style={{color:this.state.passwordIconColor}}></span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="input-group mb-3">
                                    <input type={this.state.inputType} id="pass2" class="form-control" placeholder="New Password" style={{color:this.state.passwordIconColor}}/>
                                    <div class="input-group-append" onClick={this.toggleInputType}>
                                        <div class="input-group-text">
                                        <span class="fas fa-eye" style={{color:this.state.passwordIconColor}}></span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="input-group mb-3">
                                    <input type={this.state.inputType} id="passconf" class="form-control" placeholder="Confirm Password" style={{color:this.state.passwordIconColor}}/>
                                    <div class="input-group-append" onClick={this.toggleInputType}>
                                        <div class="input-group-text">
                                        <span class="fas fa-eye" style={{color:this.state.passwordIconColor}}></span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="row justify-content-center">
                                    <button type="submit" class="btn btn-primary">Change password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                  </section>  
                  </div>     
                  </div>    
               </div>
            </section>   
            </div>
            </div>
            {/* <Footer/> */}
        </div>
    )
  }
}
