import React, { Component } from "react";

import Header from "./Header";
import Menu from "./Menu";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import $ from "jquery";
import { Invoices } from "./Invoices";

import GooglePayButton from "@google-pay/button-react";
export default class Wallet extends Component {
  state = {
    guests: [],
    guest: [],
    payment: [],
    payments: [],
    addpayment: false,
    handleClose: false,
    show: false,
    open: false,
    setShow: false,
    open: false,
    guest_id: "",
    invoices: [],
    invoice: "",
  };

  fetch() {
    var context = this;

    $.ajax({
      url: "https://yolo-student-api.jagathsoft.com/api/guest_get_invoice_details/1",
      method: "GET",
      success: function (response) {
        console.log(response.recordset);

        context.setState({
          invoices: response.recordset,
          invoice: response.recordset[0],
          guest_id: response.recordset[0].guest_id,
        });

        console.log("Before Senthil");
        console.log(context.state.guests);
        console.log(context.state.guest);
        console.log("After Senthil");
      },
      error: function (error) {
        console.log(error);
      },
    });
  }

  componentDidMount() {
    this.fetch();
  }

  constructor(props) {
    super(props);
    this.state = {
      guests: [],
      guest: [],
      payment: [],
      payments: [],
      invoice: [],
      invoices: [],
      _addpayment: false,
      get addpayment() {
        return this._addpayment;
      },
      set addpayment(value) {
        this._addpayment = value;
      },
      handleClose: false,
      show: false,
      open: false,
      setShow: false,
      open: false,
      guest_id: "",
      upi_id: "",
    };
  }

  AddPayment() {
    this.setState({
      show: true,
      addpayment: true,
      open: true,
    });
  }

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  displayRazorpay = async () => {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://yolo-student-api.jagathsoft.com/payment/orders"
    );

    console.log({ result });

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    var finalamount = $('input[id="paymentAmount"]').val();
    console.log(finalamount);
    const options = {
      key: "rzp_test_f6Fn791hjNMNiy", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Yolo Corp.",
      description: "Test Transaction",
      image: { logo: "" },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "https://yolo-student-api.jagathsoft.com/payment/success",
          data
        );

        alert(result.data.msg);
      },
      prefill: {
        name: "Venkatesh Dev",
        email: "dev@macroidapps.com",
        contact: "9999999999",
      },
      notes: {
        address: "Yolo Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  AddPaymentClose() {
    this.setState({
      show: false,
      addpayment: true,
      open: false,
    });
  }

  handleClose() {
    this.setState({
      show: false,
      addpayment: true,
      open: false,
    });
  }

  OnChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ [e.target.name]: e.target.value });
  };

  gateway() {
    this.displayRazorpay().then(console.log);
  }

  handleSubmit = (e) => {
    console.log(this.state.fathername);
    try {
      var AddPaymentData = {
        guest_id: this.state.guest_id,
      };

      axios
        .post(
          "https://yolo-student-api.jagathsoft.com/api/guest_add_payment/1",
          AddPaymentData
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.setState({
              show: false,
              addpayment: false,
            });
          } else {
            this.state({
              errmsg: "Payment update Failed",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          e.preventDefault();
          alert("Payment update Failed");
        });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const { addpayment, show, open } = this.state;

    return (
      <div className="wrapper" style={{ marginBottom: 0, height: "100%" }}>
        <Menu active="Wallet" />
        <div className="content-wrapper" style={{ margin: "0", width: "100%" }}>
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Invoice/Bills</h1>
                </div>
                {/* /.col */}

                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>
          {/* /.content-header */}
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              {/* Small boxes (Stat box) */}

              {/* /.row */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-xl-10 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-money-bill fa-rupee-sign mr-1" />
                        Invoice/Bills
                      </h3>
                      <div className="card-tools">
                        <input type="hidden" id="paymentAmount" />
                        <ul className="nav nav-pills ml-auto">
                          <li className="nav-item">
                            <Button
                              style={{ display: "none" }}
                              variant="primary"
                              id="paymentButton"
                              onClick={(e) => this.gateway()}
                            >
                              Payment
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="tab-content p-0">
                        <div className="table-responsive">
                          <table classname="table ">
                            <tbody>
                              <Invoices invoices={this.state.invoices} />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* DIRECT CHAT */}
                </section>
                {/* /.Left col */}
                {/* right col (We are only adding the ID to make the widgets sortable)*/}
                <section className="col-lg-5 connectedSortable">
                  {/* /.card */}
                </section>
                {/* right col */}
              </div>
              {/* /.row (main row) */}
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>

        <Modal
          show={show}
          onHide={addpayment}
          style={{ width: "100%", height: "100%" }}
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>Add Payment</Modal.Title>
            <button
              type="button"
              onClick={(e) => {
                this.AddPaymentClose();
              }}
            >
              <i className="fas fa-times" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  id="upidesc"
                  value={this.state.upi_desc}
                  onChange={this.OnChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="10"
                  id="upidesc"
                  value={this.state.upi_amount}
                  onChange={this.OnChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>UPI ID / Virtual Payment Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter UPI ID"
                  id="upiid"
                  value={this.state.upi_id}
                  onChange={this.OnChange}
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                this.handleClose();
              }}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Pay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
