import React, { Component } from 'react'

export  class Test extends Component {

  render() {

  return (
        <div>
            <h1>Working</h1>
        </div>
  )
  }
  
};

  export default Test;