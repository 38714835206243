import React, { Component } from 'react'
import { CChart } from '@coreui/react-chartjs'
import ProgressBar from 'react-bootstrap/ProgressBar';
import $ from 'jquery';
import Moment from 'moment';


export default class Dashboard extends Component {
  constructor(props) {
      super(props);
  }

    render() {
      const { guest, attendances,  activities} = this.props;
      const now = guest.profile_score;
      var _variant = "primary";
      
      if(guest.profile_score >= 70){
        _variant = "success";
      }
      else if(guest.profile_score >= 50 && guest.profile_score < 70){
        _variant = "warning";
      }
      const now1 = 60;
      const now2 = 20;
      const now3 = 20;

      return (
        <div className='main-content'>
          <div class="d-none d-md-block">
                <h2 class="title">Dashboard</h2>
                <div class="date">Friday, 21st October 2022</div>
          </div>
          <div class="welcome">
                <div class="row">
                    <div class="col-lg-8 col-6">
                        <h2 class="welcome-title">Hi, <span> Anthony</span></h2>
                        <p>In publishing and graphic design, Lorem ipsum is a placeholder</p>
                    </div>
                    <div class="col-lg-4 col-6">
                        <div class="welcome-img-wrap">
                            <img class="img-fluid" src="dist/img/welcome.svg" alt=""/>
                        </div>
                    </div>
                </div>
          </div>
          <div class="mt-5">
            <div class="row">
              <div class="col-lg-6">
                <div class="chart">
                    <div class="chart-head">
                      <h3 class="chart-head-title">User Attendance</h3>
                      <form action="" class="ml-auto">
                          <select name="" id="" class="form-control">
                              <option value="">Monthly</option>
                              <option value="">Weekly</option>
                          </select>
                      </form>
                    </div>
                    <div>
                        <CChart
                          className='img-fluid'
                          type="bar"
                          data={{
                            labels: attendances.map(o =>  Moment(o.Attendance_Date).format('MMM-DD') ), 
                            datasets: [
                              {
                                label: 'Entry Time',
                                backgroundColor: 'brown',
                                borderColor: 'blue',
                                pointBorderColor: "yellow",
                                pointBackgroundColor: "orange",
                                lineTension: 0.1,
                                data: attendances.map(o => (o.Room_Entering_Time.replace(':','.')) ) ,
                              },
                              {
                                label: 'Leaving Time',
                                backgroundColor: 'green',
                                borderColor: 'red',
                                pointBorderColor: "green",
                                pointBackgroundColor: "orange",
                                lineTension: 0.1,
                                data: attendances.map(o => (o.Room_Leaving_Time.replace(':','.'))) ,
                              },                      
                            ],
                          }}
                          labels="months"
                        />
                      </div>
                      {/* <div class="reference-text">
                          <div class="reference-text-1 entry-text">
                              <span></span>
                              <div>Entry Time</div>
                          </div>
                          <div class="reference-text-1 exit-text">
                              <span></span>
                              <div>Entry Time</div>
                          </div>
                      </div> */}
                  </div>
                </div>
                <div class="col-lg-6">
                <div class="chart">
                    <div class="chart-head">
                      <h3 class="chart-head-title">Library Attendance</h3>
                      <form action="" class="ml-auto">
                          <select name="" id="" class="form-control">
                              <option value="">Monthly</option>
                              <option value="">Weekly</option>
                          </select>
                      </form>
                    </div>
                    <div>
                    <CChart 
                      type="bar"
                      data={{  
                        labels: activities.map(o =>  Moment(o.Library_Date).format('MMM-DD') ),
                        datasets: [
                          {
                            label: 'Entry Time',
                            backgroundColor: 'blue',
                            borderColor: 'blue',
                            pointBorderColor: "yellow",
                            pointBackgroundColor: "orange",
                            lineTension: 0.4,
                            data: activities.map(o => (o.Library_Entering_Time).replace(':','.')),
                          },
                          {
                            label: 'Exit Time',
                            backgroundColor: 'red',
                            borderColor: 'red',
                            pointBorderColor: "red",
                            pointBackgroundColor: " ",
                            lineTension: 0.4,
                            data: activities.map(o => (o.Library_Exit_Time).replace(':','.')),
                          },
                        ],
                        
                      }}
                      labels="months"
                    
                    />
                    </div>
                      {/* <div class="reference-text">
                          <div class="reference-text-1 entry-text">
                              <span></span>
                              <div>Entry Time</div>
                          </div>
                          <div class="reference-text-1 exit-text">
                              <span></span>
                              <div>Entry Time</div>
                          </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
        </div>
      )
    }
}